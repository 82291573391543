import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "50px 0 40px 0",
	"quarkly-title": "Footer-12"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "grid",
			"lg-width": "100%",
			"flex-direction": "row",
			"lg-flex-direction": "row",
			"justify-content": "space-between",
			"width": "100%",
			"padding": "0 0px 0 0px",
			"lg-padding": "0 0px 50px 0px",
			"margin": "0px 0px 50px 0px",
			"md-flex-wrap": "wrap",
			"md-padding": "0 0px 0 0px",
			"md-margin": "0px 0px 40px 0px",
			"grid-template-columns": "repeat(3, 1fr)",
			"lg-align-items": "start",
			"grid-gap": "36px 34px",
			"md-grid-template-columns": "repeat(2, 1fr)",
			"sm-grid-template-columns": "1fr"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"lg-align-items": "flex-start",
			"justify-content": "flex-start",
			"display": "grid",
			"lg-flex-direction": "column",
			"flex-direction": "column",
			"flex-wrap": "wrap",
			"align-content": "start",
			"grid-gap": "10px 0",
			"lg-justify-content": "start"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 25px/1.5 --fontFamily-sans",
			"md-margin": "0px 0px 10px 0px",
			"children": "Меню"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "/index",
			"text-decoration-line": "initial",
			"color": "--darkL1",
			"hover-color": "--green",
			"children": "Главная страница"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"display": "flex",
			"href": "/about",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "--darkL1",
			"hover-color": "--green",
			"children": "О нас"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "--green",
			"href": "/contact-us",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "--darkL1",
			"display": "flex",
			"children": "Контакты"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"sm-width": "100%",
			"sm-padding": "0px 0px 0px 0",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 25px/1.5 --fontFamily-sans",
			"children": "Связаться с нами"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "--base",
			"color": "#5a5d64",
			"children": <>
				проспект Абая 139, Алматы 050000, Казахстан
			</>
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"href": "tel:+7 707 798 0676",
			"color": "#5a5d64",
			"text-decoration-line": "initial",
			"font": "--base",
			"display": "inline-block",
			"hover-color": "#6d32ec",
			"children": "+7 707 798 0676"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"sm-width": "100%",
			"sm-padding": "0px 0px 0px 0",
			"md-width": "100%"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 25px/1.5 --fontFamily-sans",
			"children": "Тату-салон Canvas Chronicles"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 40px 0px",
			"font": "--base",
			"color": "#5a5d64",
			"children": <>
				Мы рады помочь вам выразить свою историю через красивое и долговечное искусство.
			</>
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "block",
			"padding": "0 0px 0px 0px",
			"border-color": "--color-lightD2",
			"md-padding": "0 0px 0px 0px",
			"md-flex-direction": "column"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "#5a5d64",
			"md-margin": "0px 0px 25px 0px",
			"sm-text-align": "center",
			"text-align": "center",
			"letter-spacing": "1px",
			"children": "Тату-салон Canvas Chronicles © 2024 All rights reserved."
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Link {...override("link")} />
				<Link {...override("link1")} />
				<Link {...override("link3")} />
			</Box>
			<Box {...override("box2")}>
				<Text {...override("text1")} />
				<Text {...override("text2")} />
				<Link {...override("link4")} />
			</Box>
			<Box {...override("box3")}>
				<Text {...override("text3")} />
				<Text {...override("text4")} />
			</Box>
		</Box>
		<Box {...override("box4")}>
			<Text {...override("text5")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;